@use 'sass:list';
@use '@angular/material' as mat;
@use 'palettes';
@use 'variables/typography-levels';

@mixin form-field-theme {
	$custom-theme: mat.m2-define-light-theme(
		(
			color: (
				primary: mat.m2-define-palette(palettes.$palette-primary, 700),
				accent: mat.m2-define-palette(palettes.$palette-primary, 700)
			),
			typography:
				mat.m2-define-typography-config(
					$font-family: 'Open Sans',
					$body-1:
						mat.m2-define-typography-level(
							$font-size: list.nth(typography-levels.$body-1, 1),
							$font-weight: list.nth(typography-levels.$body-1, 2),
							$line-height: list.nth(typography-levels.$body-1, 3),
							$letter-spacing: list.nth(typography-levels.$body-1, 4)
						)
				),
			density: -3
		)
	);

	@include mat.form-field-theme($custom-theme);
	@include mat.input-theme($custom-theme);
	@include mat.select-theme($custom-theme);
}

@include form-field-theme;

// TODO: remove when remove global styles
// overwrites annoying background from knockout CSS
.mat-mdc-form-field input {
	background: none !important;
}
