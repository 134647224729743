@use 'sass:list';
@use '@angular/material' as mat;
@use 'palettes';
@use 'variables/typography-levels';

@mixin list-theme {
	$custom-theme: mat.m2-define-light-theme(
		(
			color: (
				primary: mat.m2-define-palette(palettes.$palette-primary, 700),
				accent: mat.m2-define-palette(palettes.$palette-secondary, 700)
			),
			density: -2
		)
	);

	.mdc-list-item {
		&__primary-text {
			--mdc-list-list-item-label-text-font: 'Open Sans';
			--mdc-list-list-item-label-text-size: #{list.nth(typography-levels.$body-2, 1)};
			--mdc-list-list-item-label-text-weight: #{list.nth(typography-levels.$body-2, 2)};
			--mdc-list-list-item-label-text-line-height: #{list.nth(typography-levels.$body-2, 3)};
			--mdc-list-list-item-label-text-tracking: #{list.nth(typography-levels.$body-2, 4)};
		}
	}

	@include mat.list-theme($custom-theme);
}

@include list-theme;

.q--list-item {
	display: flex;
	gap: 0.75rem;
}
