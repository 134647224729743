@use 'variables/spacings';

$all-sizes: (
	'xxxs': spacings.$spacing-xxxs,
	'xxs': spacings.$spacing-xxs,
	'xs': spacings.$spacing-xs,
	's': spacings.$spacing-s,
	'm': spacings.$spacing-m,
	'l': spacings.$spacing-l,
	'xl': spacings.$spacing-xl,
	'xxl': spacings.$spacing-xxl,
	'xxxl': spacings.$spacing-xxxl
);

@each $size, $spacing in $all-sizes {
	.q--mr-#{$size} {
		margin-right: $spacing;
	}

	.q--ml-#{$size} {
		margin-left: $spacing;
	}

	.q--mb-#{$size} {
		margin-bottom: $spacing;
	}

	.q--mt-#{$size} {
		margin-top: $spacing;
	}

	.q--mx-#{$size} {
		margin-right: $spacing;
		margin-left: $spacing;
	}

	.q--my-#{$size} {
		margin-top: $spacing;
		margin-bottom: $spacing;
	}

	.q--m-#{$size} {
		margin: $spacing;
	}

	.q--pr-#{$size} {
		padding-right: $spacing;
	}

	.q--pl-#{$size} {
		padding-left: $spacing;
	}

	.q--pb-#{$size} {
		padding-bottom: $spacing;
	}

	.q--pt-#{$size} {
		padding-top: $spacing;
	}

	.q--px-#{$size} {
		padding-right: $spacing;
		padding-left: $spacing;
	}

	.q--py-#{$size} {
		padding-top: $spacing;
		padding-bottom: $spacing;
	}

	.q--p-#{$size} {
		padding: $spacing;
	}
}
