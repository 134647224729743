﻿@use 'variables/colors';
@use 'variables/spacings';

.timepicker header {
	background-color: colors.$green-dark-900 !important;
}

.timepicker button {
	color: colors.$green-dark-900 !important;
}

.timepicker .clock-face__number > span.active {
	background-color: colors.$green-dark-900 !important;
}

.timepicker .clock-face__clock-hand {
	background-color: colors.$green-dark-900 !important;

	&::after {
		background-color: colors.$green-dark-900 !important;
	}
}

/* stylelint-disable-next-line selector-class-pattern */
.timepicker .clock-face__clock-hand_minute::before {
	border: spacings.$spacing-xxxs solid colors.$green-dark-900 !important;
}
