@use 'sass:list';
@use '@angular/material' as mat;
@use 'palettes';
@use 'variables/typography-levels';

@mixin checkbox-theme {
	$custom-theme: mat.m2-define-light-theme(
		(
			color: (
				primary: mat.m2-define-palette(palettes.$palette-primary, 700),
				accent: mat.m2-define-palette(palettes.$palette-secondary, 700)
			),
			typography:
				mat.m2-define-typography-config(
					$font-family: 'Open Sans',
					$body-2:
						mat.m2-define-typography-level(
							$font-size: list.nth(typography-levels.$body-2, 1),
							$font-weight: list.nth(typography-levels.$body-2, 2),
							$line-height: list.nth(typography-levels.$body-2, 3),
							$letter-spacing: list.nth(typography-levels.$body-2, 4)
						)
				)
		)
	);

	.mdc-checkbox {
		--mdc-checkbox-state-layer-size: 36px;
	}

	@include mat.checkbox-theme($custom-theme);
}

@include checkbox-theme;

.q--checkbox--sm {
	@include mat.checkbox-density(-2);

	.mdc-checkbox {
		--mdc-checkbox-state-layer-size: 32px;
	}
}
