@use 'sass:list';
@use '@angular/material' as mat;
@use 'palettes';
@use 'variables/typography-levels';
@use 'variables/colors';

@mixin chips-theme {
	$custom-theme: mat.m2-define-light-theme(
		(
			color: (
				primary: mat.m2-define-palette(palettes.$palette-primary, 700),
				accent: mat.m2-define-palette(palettes.$palette-secondary, 700)
			),
			typography:
				mat.m2-define-typography-config(
					$font-family: 'Open Sans',
					$body-2:
						mat.m2-define-typography-level(
							$font-size: list.nth(typography-levels.$chip, 1),
							$font-weight: list.nth(typography-levels.$chip, 2),
							$line-height: list.nth(typography-levels.$chip, 3),
							$letter-spacing: list.nth(typography-levels.$chip, 4)
						)
				),
			density: 0
		)
	);

	@include mat.chips-theme($custom-theme);
}

@include chips-theme;

.q--chips--md {
	@include mat.chips-density(0);
}

.q--chips--sm {
	@include mat.chips-density(-2);
}

%chips {
	.mdc-evolution-chip {
		&__text-label {
			display: flex;
			gap: 6px;
			cursor: pointer;
		}
	}
}

.q--chips--basic {
	@extend %chips;

	.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
		--mdc-chip-label-text-color: #{colors.$text-primary};
		--mdc-chip-elevated-container-color: #{colors.$light-700};
	}

	.mat-mdc-standard-chip.mdc-evolution-chip--disabled {
		--mdc-chip-elevated-disabled-container-color: #{colors.$light-400};
		--mdc-chip-disabled-label-text-color: #{colors.$text-primary};
	}
}

.q--chips--primary {
	@extend %chips;

	.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
		--mdc-chip-label-text-color: #{colors.$text-contrast};
		--mdc-chip-elevated-container-color: #{colors.$primary-main-700};
	}

	.mat-mdc-standard-chip.mdc-evolution-chip--disabled {
		--mdc-chip-elevated-disabled-container-color: #{colors.$primary-400};
		--mdc-chip-disabled-label-text-color: #{colors.$text-contrast};
	}
}

.q--chips--secondary {
	@extend %chips;

	.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
		--mdc-chip-label-text-color: #{colors.$text-primary};
		--mdc-chip-elevated-container-color: #{colors.$primary-a200};
	}

	.mat-mdc-standard-chip.mdc-evolution-chip--disabled {
		--mdc-chip-elevated-disabled-container-color: #{colors.$primary-a100};
		--mdc-chip-disabled-label-text-color: #{colors.$text-primary};
	}
}
