@use 'sass:list';
@use '@angular/material' as mat;
@use 'palettes';
@use 'variables/typography-levels';

@mixin radio-size($size: 42px) {
	.mat-mdc-radio-button {
		$scale: calc($size / 42px);

		--mdc-radio-state-layer-size: #{$size};

		.mdc-form-field {
			height: $size;
		}

		.mat-ripple,
		.mdc-radio__background {
			scale: $scale;
		}

		.mdc-form-field label {
			padding-right: 2px;
		}
	}
}

@mixin radio-theme {
	$custom-theme: mat.m2-define-light-theme(
		(
			color: (
				primary: mat.m2-define-palette(palettes.$palette-primary, 700),
				accent: mat.m2-define-palette(palettes.$palette-secondary, 700)
			),
			typography:
				mat.m2-define-typography-config(
					$font-family: 'Open Sans',
					$body-2:
						mat.m2-define-typography-level(
							$font-size: list.nth(typography-levels.$body-1, 1),
							$font-weight: list.nth(typography-levels.$body-1, 2),
							$line-height: list.nth(typography-levels.$body-1, 3),
							$letter-spacing: list.nth(typography-levels.$body-1, 4)
						)
				),
			density: 0
		)
	);

	@include mat.radio-theme($custom-theme);
	@include radio-size;
}

@include radio-theme;

.q--radio--sm {
	@include radio-size(38px);

	.mat-mdc-radio-button .mdc-form-field label {
		padding-right: 0;
	}
}

.q--radio--md {
	@include radio-size;
}

.q--radio--lg {
	@include radio-size(46px);

	.mat-mdc-radio-button .mdc-form-field label {
		padding-right: 4px;
	}
}
