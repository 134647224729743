@use 'variables/colors';

.linkable {
	color: colors.$action-600;
	text-decoration: underline;

	&:hover {
		color: colors.$primary-main-700;
	}
}
