@use 'sass:list';
@use '@angular/material' as mat;
@use 'palettes';
@use 'variables/colors';
@use 'variables/spacings';
@use 'variables/typography-levels';

@mixin dialog-theme {
	$custom-theme: mat.m2-define-light-theme(
		(
			color: (
				primary: mat.m2-define-palette(palettes.$palette-primary, 700),
				accent: mat.m2-define-palette(palettes.$palette-secondary, 700)
			)
		)
	);

	.q--dialog {
		.mat-mdc-dialog-container .mat-mdc-dialog-title {
			--mdc-dialog-subhead-color: #{colors.$text-primary};
			--mdc-dialog-subhead-line-height: #{list.nth(typography-levels.$h3, 3)};
			--mdc-dialog-subhead-size: #{list.nth(typography-levels.$h3, 1)};
			--mdc-dialog-subhead-weight: #{list.nth(typography-levels.$h3, 2)};
			--mdc-dialog-subhead-tracking: #{list.nth(typography-levels.$h3, 4)};
			--mat-dialog-headline-padding: 0;

			margin: unset !important;

			&::before {
				content: unset !important;
			}
		}

		.mat-mdc-dialog-content {
			--mat-dialog-with-actions-content-padding: 0;
		}

		.mat-mdc-dialog-actions {
			--mat-dialog-actions-padding: 0;

			min-height: unset !important;
		}

		.mat-mdc-dialog-component-host > * {
			padding: spacings.$spacing-m;
		}
	}

	@include mat.dialog-theme($custom-theme);
}

@include dialog-theme;
