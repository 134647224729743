@use '@angular/material' as mat;
@use 'palettes';
@use 'variables/colors';

@mixin card-theme {
	$custom-theme: mat.m2-define-light-theme(
		(
			color: (
				primary: mat.m2-define-palette(palettes.$palette-primary, 700),
				accent: mat.m2-define-palette(palettes.$palette-secondary, 700)
			)
		)
	);

	.mdc-card--outlined {
		--mdc-outlined-card-container-shape: 8px;
		--mdc-outlined-card-container-color: #{colors.$primary-contrast};
	}

	@include mat.card-theme($custom-theme);
}

@include card-theme;
