@use 'sass:list';
@use '@angular/material' as mat;
@use 'palettes';
@use 'variables/typography-levels';
@use 'variables/colors';

@mixin button-toggle-typography($typography-level) {
	.mat-button-toggle-appearance-standard {
		.mat-button-toggle-label-content {
			font-size: list.nth($typography-level, 1);
			font-weight: list.nth($typography-level, 2);
			letter-spacing: list.nth($typography-level, 4);
		}

		&:not(.mat-button-toggle-disabled) {
			color: colors.$text-primary;
		}
	}
}

@mixin button-toggle-theme {
	$custom-theme: mat.m2-define-light-theme(
		(
			color: (
				primary: mat.m2-define-palette(palettes.$palette-primary, 700),
				accent: mat.m2-define-palette(palettes.$palette-secondary, 700)
			),
			density: -3
		)
	);

	.mat-button-toggle-checked {
		background-color: colors.$green-100 !important;
	}

	.mat-button-toggle-appearance-standard:hover {
		background-color: colors.$primary-a200;
	}

	@include mat.button-toggle-theme($custom-theme);
	@include button-toggle-typography(typography-levels.$button-m);
}

@include button-toggle-theme;

.q--btn-toogle--sm {
	@include mat.button-toggle-density(-4.5);
	@include button-toggle-typography(typography-levels.$button-s);
}

.q--btn-toogle--md {
	@include mat.button-toggle-density(-3);
	@include button-toggle-typography(typography-levels.$button-m);
}

.q--btn-toogle--lg {
	@include mat.button-toggle-density(-1.5);
	@include button-toggle-typography(typography-levels.$button-l);
}
