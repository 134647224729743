/* open-sans-300 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	src: url('../../assets/fonts/open-sans/open-sans-v40-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}

/* open-sans-regular - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url('../../assets/fonts/open-sans/open-sans-v40-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}

/* open-sans-500 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 500;
	src: url('../../assets/fonts/open-sans/open-sans-v40-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}

/* open-sans-600 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	src: url('../../assets/fonts/open-sans/open-sans-v40-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}

/* open-sans-700 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: url('../../assets/fonts/open-sans/open-sans-v40-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}

/* open-sans-800 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	src: url('../../assets/fonts/open-sans/open-sans-v40-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}
