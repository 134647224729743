﻿.mat-stepper-vertical,
.mat-stepper-horizontal {
	font-family: 'Open Sans', sans-serif;
	font-style: normal;
}

.mat-step-label {
	font-size: 14px;
	font-weight: 600;
	line-height: 19px;
}
