@use 'variables/colors';
@use 'variables/spacings';
@forward 'common';
@forward 'dialog';
@forward 'expansion-panel';
@forward 'hover';
@forward 'stepper';
@forward 'time-picker';

.wr-create-stepper {
	.ngx-timepicker {
		justify-content: space-between;
		border-bottom: 1px solid rgb(0 0 0 / 42%);
	}

	.mat-stepper-horizontal-line {
		flex: auto;
		min-width: spacings.$spacing-m;
		height: 0;
		margin: 0;
		border-top: 3px solid !important;
	}

	.mat-step-icon-selected {
		background-color: colors.$blue-400;
	}

	.green-border {
		border-color: colors.$green-main-700 !important;
	}
}

.disable-tab {
	pointer-events: none;
}

.disable-tab .mat-step-icon {
	color: colors.$primary-contrast;
	background-color: rgb(0 0 0 / 54%);
}

.transparent-background {
	background-color: transparent !important;
}

// Custom scrollbar with "auto-hiding"
.insights-scrollbar {
	scrollbar-color: transparent transparent;
	scrollbar-width: thin;
	transition: scrollbar-color 0.5s;

	&:hover {
		scrollbar-color: colors.$text-disabled transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: colors.$text-disabled;
		border-radius: 2px;
	}

	&:not(:hover)::-webkit-scrollbar-thumb {
		background-color: transparent;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
		box-shadow: none;
	}

	&::-webkit-scrollbar {
		width: 0.35vw;
		background-color: transparent;
	}
}

// BOOTSTRAP OVERRIDES
// sidenav max width, avoid having modal under sidenav
.sidenav-container .modal {
	margin-top: 3.125rem;
}

:not(.mobile).sidenav-container {
	&.wide-sidenav .modal {
		margin-left: 16rem !important;
	}

	&.slim-sidenav .modal {
		margin-left: 3.125rem !important;
	}
}
