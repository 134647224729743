@use 'sass:list';
@use '@angular/material' as mat;
@use 'palettes';
@use 'variables/typography-levels';
@use 'variables/colors';
@use 'variables/spacings';

@mixin table-theme {
	$custom-theme: mat.m2-define-light-theme(
		(
			color: (
				primary: mat.m2-define-palette(palettes.$palette-primary, 700),
				accent: mat.m2-define-palette(palettes.$palette-secondary, 700)
			),
			typography:
				mat.m2-define-typography-config(
					$font-family: 'Open Sans',
					$subtitle-2:
						mat.m2-define-typography-level(
							$font-size: list.nth(typography-levels.$body-2, 1),
							$font-weight: list.nth(typography-levels.$body-2, 2),
							$line-height: list.nth(typography-levels.$body-2, 3),
							$letter-spacing: list.nth(typography-levels.$body-2, 4)
						)
				),
			density: 0
		)
	);

	@include mat.table-theme($custom-theme);

	.mat-mdc-header-row {
		height: 32px !important;
	}

	.mat-mdc-header-cell {
		font-size: list.nth(typography-levels.$h5, 1) !important;
		font-weight: list.nth(typography-levels.$h5, 2) !important;
		line-height: list.nth(typography-levels.$h5, 3) !important;
		letter-spacing: list.nth(typography-levels.$h5, 4) !important;
	}

	.mat-mdc-row {
		height: 56px !important;
	}

	.mat-mdc-row,
	.mat-mdc-footer-row,
	.mat-mdc-header-row {
		min-height: 32px !important;
	}

	.mat-mdc-cell:first-of-type,
	.mat-mdc-header-cell:first-of-type,
	.mat-mdc-footer-cell:first-of-type {
		padding-left: spacings.$spacing-xs !important;
	}

	.mat-mdc-cell:last-of-type,
	.mat-mdc-header-cell:last-of-type,
	.mat-mdc-footer-cell:last-of-type {
		padding-right: spacings.$spacing-xs !important;
	}
}

@include table-theme;

.q--table--sm {
	@include mat.table-density(-4);

	.mat-mdc-row {
		height: 32px !important;
	}
}

.q--table--hoverable {
	.mat-mdc-row:hover {
		background-color: colors.$light-500 !important;
	}
}

.q--table-pagination {
	@include mat.form-field-density(-5);
	@include mat.icon-button-density(-4.5);
}
