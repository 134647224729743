// PRIMARY
$primary-50: #f5f9f7;
$primary-100: #c6dfd6;
$primary-200: #98c6b5;
$primary-300: #69ac94;
$primary-400: #3b9373;
$primary-light-500: #0d7a53;
$primary-600: #0b6947;
$primary-main-700: #0d5f41;
$primary-800: #0b5238;
$primary-dark-900: #09402c;
$primary-a100: #f0f5f4;
$primary-a200: #e8edec;
$primary-a400: #c5dbd4;
$primary-a700: #09402c;
$primary-contrast: #fff;
$primary-contrast-dark: #323232;

// SECONDARY
$secondary-50: #f9fbfa;
$secondary-100: #f4f6f6;
$secondary-200: #e8edec;
$secondary-300: #ced9d7;
$secondary-400: #b5c5c2;
$secondary-light-500: #9bb1ac;
$secondary-600: #819c97;
$secondary-main-700: #698681;
$secondary-800: #556d68;
$secondary-dark-900: #41534f;
$secondary-a100: null;
$secondary-a200: null;
$secondary-a400: null;
$secondary-a700: null;

// RED
$red-50: #fef8f8;
$red-100: #fdf1f1;
$red-200: #fceaeb;
$red-300: #f8cacc;
$red-400: #f2a0a2;
$red-light-500: #eb7677;
$red-600: #db564d;
$red-main-700: #cb3935;
$red-800: #9e2626;
$red-dark-900: #711216;
$red-a100: #fdecee;
$red-a200: null;
$red-a400: null;
$red-a700: #511715;

// GREEN
$green-50: #f0faf3;
$green-100: #e1f5e6;
$green-200: #c2eacd;
$green-300: #a4e0b5;
$green-400: #85d69c;
$green-light-500: #67cb83;
$green-600: #48c16a;
$green-main-700: #39a858;
$green-800: #2f8948;
$green-dark-900: #1f5b30;
$green-a100: #effaf6;
$green-a200: null;
$green-a400: null;
$green-a700: #265443;

// BLUE
$blue-50: #f7f9fc;
$blue-100: #dce7f8;
$blue-200: #c1d5f4;
$blue-300: #a6c3f0;
$blue-400: #8bb2ec;
$blue-light-500: #70a0e8;
$blue-600: #558ee4;
$blue-main-700: #3b7de0;
$blue-800: #3570c9;
$blue-dark-900: #2f65b5;
$blue-a100: #eaf0f7;
$blue-a200: null;
$blue-a400: null;
$blue-a700: #132846;

// YELLOW
$yellow-50: #fffdf9;
$yellow-100: #fefaed;
$yellow-200: #fdf7e0;
$yellow-300: #fdf4d3;
$yellow-400: #fad5aa;
$yellow-light-500: #f6b580;
$yellow-600: #f29129;
$yellow-main-700: #ed6c02;
$yellow-800: #e0640e;
$yellow-dark-900: #d35b26;
$yellow-a100: #fefbf0;
$yellow-a200: null;
$yellow-a400: null;
$yellow-a700: #64572b;

// LIGHT
$light-50: #fff;
$light-100: #fcfcfc;
$light-200: #f9f9f9;
$light-300: #f6f6f6;
$light-400: #f3f3f3;
$light-500: #f0f0f0;
$light-600: #ededed;
$light-700: #eaeaea;
$light-800: #e7e7e7;
$light-900: #e4e4e4;

// DARK
$dark-50: #cdcdcd;
$dark-100: #b6b6b6;
$dark-200: #a1a1a1;
$dark-300: #888;
$dark-400: #727272;
$dark-500: #5b5b5b;
$dark-600: #444;
$dark-700: #2d2d2d;
$dark-800: #161616;
$dark-900: #000;

// DEPRECATED
// ACTION COLORS
$action-hover-50: #fafafa;
$action-selected-100: #ebebeb;
$action-disabled-background-200: #e0e0e0;
$action-focus-300: #e0e0e0;
$action-disabled-400: #bdbdbd;
$action-500: #a8a8a8;
$action-600: #939393;
$action-active-700: #757575;
$action-800: #5a5a5a;
$action-900: #404040;

// TEXT COLORS
$text-primary: $dark-600;
$text-secondary: $dark-400;
$text-disabled: $dark-100;
$text-main: $primary-main-700;
$text-error: $red-a700;
$text-warning: $yellow-a700;
$text-info: $blue-a700;
$text-success: $green-a700;
$text-contrast: $primary-contrast;

// MISC COLORS
$divider: #e0e0e0;
$outline-border: #c4c4c4;
$standard-input-line: #949494;
$backdrop-overlay: rgb(0 0 0 / 50%);
