.cdk-drag {
	&-preview {
		overflow: hidden;
		box-shadow: none !important;
	}

	&-animating {
		transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
	}
}
