@use 'variables/colors';

:root {
	--qit-primary-color: #{colors.$primary-contrast};
	--qit-secondary-color: #{colors.$text-primary};
	--qit-text-primary: #{colors.$text-primary};
	--qit-text-secondary: #{colors.$text-secondary};
	--qit-text-contrast: #{colors.$primary-contrast};

	.qit--dark-theme {
		--qit-primary-color: #{colors.$primary-contrast-dark};
		--qit-secondary-color: #{colors.$primary-contrast};
		--qit-text-primary: #{colors.$primary-contrast};
		--qit-text-secondary: #{colors.$dark-50};
		--qit-text-contrast: #{colors.$primary-contrast-dark};
	}
}
