@use 'variables/colors';
@use 'highcharts/css/highcharts';

$qit-highcharts-colors: (
	0: #19be81,
	1: #ffbb54,
	2: #0d5f41,
	3: #e84d56,
	4: #2f65b0,
	5: #82a3d0,
	6: #731804,
	7: #249c86,
	8: #fc9d5d,
	9: #857c45,
	10: #4f175e,
	11: #bf225e,
	12: #41e8ca,
	13: #b06838,
	14: #9882d0,
	15: #b0a32f,
	16: #1d5135,
	17: #c27f29,
	18: #59bb86,
	19: #f3dec8,
	20: #5abb86,
	21: #d3913b,
	22: #bd4d50,
	23: #4aa372,
	24: #f1d7bc,
	25: #7693bf,
	26: #88a3cc,
	27: #2b5e43,
	28: #eea649,
	29: #70bf94,
	30: #d7585b,
	31: #f3ddc8,
	32: #ddba99,
	33: #f5e5d4
);

:root,
.qit--light-theme {
	--highcharts-background-color: #{colors.$primary-contrast};

	/* Neutral color variations */
	--highcharts-neutral-color-100: #000;
	--highcharts-neutral-color-80: #333;
	--highcharts-neutral-color-60: #666;
	--highcharts-neutral-color-40: #999;
	--highcharts-neutral-color-20: #ccc;
	--highcharts-neutral-color-10: #e6e6e6;
	--highcharts-neutral-color-5: #f2f2f2;
	--highcharts-neutral-color-3: #f7f7f7;

	/* Highlight color variations */
	--highcharts-highlight-color-100: #02f;
	--highcharts-highlight-color-80: #334eff;
	--highcharts-highlight-color-60: #667aff;
	--highcharts-highlight-color-20: #ccd3ff;
	--highcharts-highlight-color-10: #e6e9ff;

	@each $key, $color in $qit-highcharts-colors {
		--highcharts-color-#{$key}: #{$color};
	}
}

.qit--dark-theme {
	--highcharts-background-color: #{colors.$primary-contrast-dark};

	/* Neutral color variations */
	--highcharts-neutral-color-100: rgb(255 255 255);
	--highcharts-neutral-color-80: rgb(214 214 214);
	--highcharts-neutral-color-60: rgb(173 173 173);
	--highcharts-neutral-color-40: rgb(133 133 133);
	--highcharts-neutral-color-20: rgb(92 92 92);
	--highcharts-neutral-color-10: rgb(71 71 71);
	--highcharts-neutral-color-5: rgb(61 61 61);
	--highcharts-neutral-color-3: rgb(57 57 57);

	/* Highlight color variations */
	--highcharts-highlight-color-100: rgb(122 167 255);
	--highcharts-highlight-color-80: rgb(108 144 214);
	--highcharts-highlight-color-60: rgb(94 121 173);
	--highcharts-highlight-color-20: rgb(65 74 92);
	--highcharts-highlight-color-10: rgb(58 63 71);
}
