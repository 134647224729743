@use 'utils';

.mat-expansion-panel-header {
	padding: utils.$spacing-xs !important; // change default padding
	font-size: utils.$spacing-xs !important; // important so it is not overwritten by main.css
}

.mat-expansion-panel-body {
	padding: utils.$spacing-xxs utils.$spacing-xs utils.$spacing-xs !important; // change default padding
}
