@use 'sass:map';
@use 'sass:list';
@use '@angular/material' as mat;
@use 'palettes';
@use 'variables/colors';
@use 'variables/typography-levels';

@mixin _button-typography($font-size, $font-weight, $line-height, $letter-spacing) {
	$typography: mat.m2-define-typography-config(
		$font-family: 'Open Sans',
		$button: mat.m2-define-typography-level(
				$font-size: $font-size,
				$font-weight: $font-weight,
				$line-height: $line-height,
				$letter-spacing: $letter-spacing
			)
	);

	@include mat.button-typography($typography);
}

@mixin _button-color($primary, $shade: 700) {
	$theme: mat.m2-define-light-theme(
		(
			color: (
				primary: mat.m2-define-palette($primary, $shade),
				accent: mat.m2-define-palette($primary, $shade)
			)
		)
	);

	@include mat.button-color($theme);

	.mdc-button--outlined:not(:disabled) {
		--mdc-outlined-button-outline-color: map.get($primary, calc($shade - 100));
	}
}

@mixin button-theme {
	$custom-theme: mat.m2-define-light-theme(
		(
			color: (
				primary: mat.m2-define-palette(palettes.$palette-primary, 700),
				accent: mat.m2-define-palette(palettes.$palette-secondary, 200),
				warn: mat.m2-define-palette(palettes.$palette-red, 700)
			),
			typography: mat.m2-define-typography-config(
					$font-family: 'Open Sans',
					$button: mat.m2-define-typography-level(
							$font-size: list.nth(typography-levels.$button-m, 1),
							$font-weight: list.nth(typography-levels.$button-m, 2),
							$line-height: list.nth(typography-levels.$button-m, 3),
							$letter-spacing: list.nth(typography-levels.$button-m, 4)
						)
				),
			density: 0
		)
	);

	@include mat.button-theme($custom-theme);

	.mdc-button--outlined:not(:disabled)[color='primary'] {
		--mdc-outlined-button-outline-color: map.get(palettes.$palette-primary, 600);
	}

	.mdc-button--outlined:not(:disabled)[color='accent'] {
		--mdc-outlined-button-outline-color: map.get(palettes.$palette-secondary, 600);
	}

	.mdc-button--outlined:not(:disabled)[color='warn'] {
		--mdc-outlined-button-outline-color: map.get(palettes.$palette-red, 600);
	}

	.mat-mdc-unelevated-button[disabled][disabled] {
		--mdc-filled-button-disabled-container-color: #{colors.$action-disabled-background-200};
		--mdc-filled-button-disabled-label-text-color: #{colors.$text-disabled};
	}

	.mat-mdc-button[disabled][disabled] {
		--mdc-text-button-disabled-label-text-color: #{colors.$text-disabled};
	}

	.mat-mdc-outlined-button[disabled][disabled] {
		--mdc-outlined-button-disabled-outline-color: #{colors.$action-disabled-background-200};
		--mdc-outlined-button-label-text-color: #{colors.$text-disabled};
	}
}

@include button-theme;

.q--btn--sm {
	--mdc-outlined-button-container-height: 30px;
	--mdc-filled-button-container-height: 30px;
	--mdc-text-button-container-height: 30px;

	@include _button-typography(typography-levels.$button-s...);
	@include mat.button-density(-2);
}

.q--btn--md {
	@include _button-typography(typography-levels.$button-m...);
	@include mat.button-density(0);
}

.q--btn--lg {
	--mdc-outlined-button-container-height: 42px;
	--mdc-filled-button-container-height: 42px;
	--mdc-text-button-container-height: 42px;

	@include _button-typography(typography-levels.$button-l...);
}

.q--btn--primary {
	@include _button-color(palettes.$palette-primary);
}

.q--btn--accent {
	@include _button-color(palettes.$palette-secondary, 200);
}

.q--btn--error {
	@include _button-color(palettes.$palette-red);
}

.q--btn--warning {
	@include _button-color(palettes.$palette-yellow);
}

.q--btn--info {
	@include _button-color(palettes.$palette-blue);
}

.q--btn--success {
	@include _button-color(palettes.$palette-green);
}

.q--btn--basic-light {
	@include _button-color(palettes.$palette-light);
}

.q--btn--basic-dark {
	@include _button-color(palettes.$palette-dark, 600);
}

a.mat-mdc-button:hover,
a.mat-mdc-button:focus {
	text-decoration: none;
}
