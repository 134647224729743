// TODO: UPDATE WITH NEW DESIGN SYSTEM
::-webkit-scrollbar-track {
	background-color: #f5f5f5;
	border-radius: 2px;
	box-shadow: inset 0 0 4px rgb(0 0 0 / 30%);
}

::-webkit-scrollbar {
	width: 0.4vw; // it doesn't change on zoom
	background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
	background-color: #8a8b8f;
	border-radius: 3px;
}

// TODO: THIS NEEDS TO BE REMOVED WHEN WE GET RID OF OLD KNOCKOUT STYLES
* {
	scrollbar-color: inherit;
	scrollbar-width: inherit;
}
