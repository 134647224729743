$flex-values: (
	'xxxs': 0,
	'xxs': 0.25,
	'xs': 0.5,
	's': 0.75,
	'm': 1.5,
	'l': 2,
	'xl': 2.5,
	'xxl': 3,
	'xxxl': 4
);

@each $item, $flex in $flex-values {
	.q--flex-#{$item} {
		flex: $flex;
	}
}
