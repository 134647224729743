// SPACINGS
$spacing-xxxs: 0.25rem;
$spacing-xxs: 0.5rem;
$spacing-xs: 1rem;
$spacing-s: 1.5rem;
$spacing-m: 2rem;
$spacing-l: 2.5rem;
$spacing-xl: 3rem;
$spacing-xxl: 3.5rem;
$spacing-xxxl: 4rem;
$spacing-xxxxxl: 4.875rem;
