@use 'variables/colors';

.hover:hover {
	z-index: 999;
	box-shadow: none !important;

	&.blue {
		background-color: colors.$blue-50 !important;
	}
}
