@use 'sass:map';
@use 'palettes';
@use 'variables/colors';
@use '@angular/material' as mat;

@mixin _chips-status-color($primary, $shade: 700) {
	$theme: mat.m2-define-light-theme(
		(
			color: (
				primary: mat.m2-define-palette($primary, $shade),
				accent: mat.m2-define-palette($primary, $shade)
			)
		)
	);

	.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled, .mat-mdc-chip-option) {
		--mdc-chip-elevated-container-color: #{map.get($primary, 100)};

		border: 1px solid map.get($primary, 500);

		.mdc-evolution-chip__text-label {
			--mdc-chip-label-text-color: #{map.get($primary, 700)};
		}
	}

	.mat-mdc-standard-chip.mdc-evolution-chip--disabled {
		--mdc-chip-elevated-disabled-container-color: transparent;

		border: 1px solid map.get($primary, 400);

		.mdc-evolution-chip__text-label {
			--mdc-chip-disabled-label-text-color: #{map.get($primary, 400)};
		}
	}

	@include mat.chips-color($theme);
}

.q--chips-status--sm {
	.mat-mdc-chip.mat-mdc-standard-chip {
		--mdc-chip-container-height: 24px;
	}
}

.q--chips-status--basic {
	@include _chips-status-color(palettes.$palette-dark, 700);

	.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled, .mat-mdc-chip-option) {
		--mdc-chip-elevated-container-color: #{colors.$light-300};

		border: 1px solid colors.$dark-200;
	}
}

.q--chips-status--red {
	@include _chips-status-color(palettes.$palette-red, 700);
}

.q--chips-status--yellow {
	@include _chips-status-color(palettes.$palette-yellow, 700);
}

.q--chips-status--blue {
	@include _chips-status-color(palettes.$palette-blue, 700);
}

.q--chips-status--green {
	@include _chips-status-color(palettes.$palette-green, 700);
}
