/* remove user agent styles */
@use 'normalize-scss/sass/normalize/import-now';

html {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: unset;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

// TODO: THIS SHOULD BE REMOVED AFTER WE FIX THE FONT SIZE EVERYWHERE
body {
	font-size: 12px;
}
