@use 'sass:list';
@use '@angular/material' as mat;
@use 'palettes';
@use 'variables/typography-levels';
@use 'variables/colors';

@mixin snack-bar-theme {
	$custom-theme: mat.m2-define-light-theme(
		(
			color: (
				primary: mat.m2-define-palette(palettes.$palette-primary, 700),
				accent: mat.m2-define-palette(palettes.$palette-secondary, 700)
			)
		)
	);

	.mat-mdc-snack-bar-container .mdc-snackbar__label {
		--mdc-snackbar-supporting-text-font: 'Open Sans';
		--mdc-snackbar-supporting-text-size: #{list.nth(typography-levels.$h4, 1)};
		--mdc-snackbar-supporting-text-weight: #{list.nth(typography-levels.$h4, 2)};
		--mdc-snackbar-supporting-text-line-height: #{list.nth(typography-levels.$h4, 3)};
	}

	@include mat.snack-bar-theme($custom-theme);
}

@include snack-bar-theme;

.q--snack-bar--success {
	--mdc-snackbar-container-color: #{colors.$green-main-700};
	--mdc-snackbar-supporting-text-color: #{colors.$text-contrast};
}

.q--snack-bar--warning {
	--mdc-snackbar-container-color: #{colors.$yellow-main-700};
	--mdc-snackbar-supporting-text-color: #{colors.$text-contrast};
}

.q--snack-bar--error {
	--mdc-snackbar-container-color: #{colors.$red-main-700};
	--mdc-snackbar-supporting-text-color: #{colors.$text-contrast};
}

.q--snack-bar--info {
	--mdc-snackbar-container-color: #{colors.$blue-main-700};
	--mdc-snackbar-supporting-text-color: #{colors.$text-contrast};
}
