@use 'sass:list';
@use '@angular/material' as mat;
@use 'palettes';
@use 'variables/typography-levels';

@mixin slide-toggle-theme {
	$custom-theme: mat.m2-define-light-theme(
		(
			color: (
				primary: mat.m2-define-palette(palettes.$palette-primary, 700),
				accent: mat.m2-define-palette(palettes.$palette-secondary, 700)
			),
			typography:
				mat.m2-define-typography-config(
					$font-family: 'Open Sans',
					$body-2:
						mat.m2-define-typography-level(
							$font-size: list.nth(typography-levels.$body-2, 1),
							$font-weight: list.nth(typography-levels.$body-2, 2),
							$line-height: list.nth(typography-levels.$body-2, 3),
							$letter-spacing: list.nth(typography-levels.$body-2, 4)
						)
				),
			density: 0
		)
	);

	@include mat.slide-toggle-theme($custom-theme);
}

@include slide-toggle-theme;

.mat-mdc-slide-toggle .mdc-switch.mdc-switch {
	&--selected:enabled .mdc-switch__icon {
		--mdc-switch-selected-icon-color: transparent;
	}

	&--unselected:enabled .mdc-switch__icon {
		--mdc-switch-unselected-icon-color: transparent;
	}
}
