@use 'sass:list';
@use '@angular/material' as mat;
@use 'palettes';
@use 'variables/typography-levels';

@mixin icon-button-theme {
	$custom-theme: mat.m2-define-light-theme(
		(
			color: (
				primary: mat.m2-define-palette(palettes.$palette-primary, 700),
				accent: mat.m2-define-palette(palettes.$palette-secondary, 700)
			),
			typography: mat.m2-define-typography-config(
					$font-family: 'Open Sans',
					$button: mat.m2-define-typography-level(
							$font-size: list.nth(typography-levels.$button-m, 1),
							$font-weight: list.nth(typography-levels.$button-m, 2),
							$line-height: list.nth(typography-levels.$button-m, 3),
							$letter-spacing: list.nth(typography-levels.$button-m, 4)
						)
				),
			density: -3
		)
	);

	@include mat.icon-button-theme($custom-theme);
}

@include icon-button-theme;

@mixin icon-button-size($size: 1.25rem) {
	fa-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		color: var(--qit-text-secondary);

		svg {
			width: $size;
			height: $size;
		}
	}

	fa-layers {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}
}

.mat-mdc-icon-button {
	@include icon-button-size;
}

.q--icon-btn--lg {
	@include mat.icon-button-density(-1.5);
	@include icon-button-size(1.6875rem);
}

.q--icon-btn--md {
	@include mat.icon-button-density(-3);
	@include icon-button-size;
}

.q--icon-btn--sm {
	@include mat.icon-button-density(-4.5);
	@include icon-button-size(1.125rem);
}

.q--icon-btn--xs {
	@include mat.icon-button-density(-6);
	@include icon-button-size(0.9375rem);
}
