// go-modal-content is class that is added to modals that should overwrite AM default paddings
// we will later used this to define same padding for all of modals on our platform
@use 'variables/colors';

.go-modal-content .mat-dialog-container {
	padding: 0 !important;
	box-shadow: none;
}

.cdk-overlay-container {
	// this is important to overlay over navbar with z-index: 1030
	z-index: 1100 !important;
}

.cdk-overlay-backdrop-showing {
	background-color: colors.$backdrop-overlay;
}
