@use 'sass:list';
@use '@angular/material' as mat;
@use 'palettes';
@use 'variables/typography-levels';

@mixin paginator-theme {
	$custom-theme: mat.m2-define-light-theme(
		(
			color: (
				primary: mat.m2-define-palette(palettes.$palette-primary, 700),
				accent: mat.m2-define-palette(palettes.$palette-secondary, 700)
			),
			typography:
				mat.m2-define-typography-config(
					$font-family: 'Open Sans',
					$caption:
						mat.m2-define-typography-level(
							$font-size: list.nth(typography-levels.$caption, 1),
							$font-weight: list.nth(typography-levels.$caption, 2),
							$line-height: list.nth(typography-levels.$caption, 3),
							$letter-spacing: list.nth(typography-levels.$caption, 4)
						)
				),
			density: -4
		)
	);

	@include mat.paginator-theme($custom-theme);
}

@include paginator-theme;

mat-paginator {
	--mdc-outlined-text-field-outline-color: transparent;
}
